export interface Route {
	url: string;
	text: string;
	img?: string;
}

//internal routes
export const home: Route = { text: 'Home', url: '/' };
export const products: Route = { text: 'Products', url: '/products/' };
export const photographyPilot: Route = { text: 'Photography pilot', url: '/photography-pilot/' };
export const aboutUs: Route = { text: 'About us', url: '/about-us/' };

// social media
export const discord: Route = {
	text: 'Discord',
	url: 'https://discordapp.com/invite/WHe4EuY',
	img: '/discord.svg'
};
export const twitter: Route = {
	text: 'Twitter',
	url: 'https://twitter.com/kelp_digital',
	img: '/twitter.svg'
};
export const instagram: Route = {
	text: 'Instagram',
	url: 'https://www.instagram.com/kelp.digital/',
	img: '/instagram.svg'
};
export const linkedin: Route = {
	text: 'Linkedin',
	url: 'https://www.linkedin.com/company/kelp-digital/',
	img: '/linkedin.svg'
};

export const anagolayUrl = 'https://anagolay.network/';
export const maculaUrl = 'https://macula.link/';

export const twitterHandler = '@kelp_digital';

export interface ContentLink {
	title: string;
	description: string;
	imageUrl: string;
	imageAlt: string;
	keywords: string;
	url: string;
	siteName: string;
}

export const contentLinks: { [key: string]: ContentLink } = {
	home: {
		title: 'Kelp Digital - Your Art. Your Terms.',
		description:
			'Rights management software empowered by cutting-edge p2p technology to help all content creators — from photographers & visual artists to musicians and developers — regain control of their work online and share and license it on their terms.',
		imageUrl: 'https://u.macula.link/dxMgILKpQv2e4J41dSzg6A-7?preset=open_graph',
		imageAlt: 'Media Platform text with black logo on white background',
		keywords:
			'kelp, kelp digital, content creators, photography, ownership economy,  copyright statements, ownership statements, web3, rights management, restore creative balance',
		url: 'https://kelp.digital/',
		siteName: 'Kelp Digital'
	},
	aboutUs: {
		title: 'Kelp Digital - About Us',
		description: `Kelp is a remote distributed team on a mission to shift the power from the centralized closed systems to individual creators by combining cutting edge technology with creative powers of our community`,
		imageUrl: 'https://u.macula.link/eLOAQCl_T9CsGCSCcCoy4w-7?preset=open_graph',
		imageAlt: 'Kelp Digital team photo',
		keywords:
			'kelp, kelp digital, content creators, photography, ownership economy,  copyright statements, ownership statements',
		url: 'https://kelp.digital/about-us/',
		siteName: 'Kelp Digital - About Us'
	},
	photographyPilot: {
		title: 'Kelp Digital for photographers',
		description: `Distributed user-centric software for the digital photography market. 
	Kelp's digital copyright statements help photographers protect, share and license 
	photos they take. Simple, transparent, and secure.`,
		imageUrl: 'https://u.macula.link/GcnUkxrdRjaMlT_-9JyZhQ-7?preset=open_graph',
		imageAlt: 'Share icon with images around on purple background',
		keywords: `kelp, kelp digital, photography, photo copyright, 
	copyright statements, camera ownership, photo rights management, web3, 
	restore creative balance`,
		url: 'https://kelp.digital/photography-pilot/',
		siteName: 'Kelp Digital - Photography Pilot'
	},
	products: {
		title: 'Kelp Digital tech & products',
		description: `We build the essential tech infrastructure and distributed software for the emerging ownership economy and the fair market for digital creators`,
		imageUrl: 'https://u.macula.link/R0fkTD1WTxGayiVevgPjGw-7?preset=open_graph',
		imageAlt: 'Jellyfish brain with locks and coins in tentacles on purple background',
		keywords: `kelp, kelp digital, anagolay, idiyanale, macula, remote signer, content creators, photography, ownership, economy, copyright statements, ownership statements, web3, rights management, on-the-fly image resizing`,
		url: 'https://kelp.digital/products/',
		siteName: 'Kelp Digital tech & products'
	}
} as const;
